@import url('./assets/fonts/montserrat-bold/fonts.css');
@import url('./assets/fonts/montserrat-regular/fonts.css');
@import url('./assets/fonts/roboto-regular/fonts.css');
@import url('./assets/fonts/roboto-bold/fonts.css');

html {
  font-family: 'roboto', sans-serif;
}

html body {
  font-family: 'roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'montserrat', sans-serif;
}

button {
  font-family: 'montserrat', sans-serif;
}

.montserrat {
  font-family: 'montserrat', sans-serif;
}